import React, { useState, useEffect } from 'react';
// MUI imports
import { Autocomplete, TextField } from '@mui/material';
//Apollo Client Imports
import { useLazyQuery } from '@apollo/client';
import { GET_STORES_LIST_ADS } from 'services/store-service';
import { dashboardServiceClient } from 'graphql/client';
//Custom Imports
import useDebounce from 'layouts/product-category/useDebounce';

function StoreDropdown({setSelectStore}) {
  const [storeOptions, setStoreOptions] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const debouncedSearchTerm = useDebounce(inputValue, 300);

  const [
    getStoreList,
    { loading: storeListLoading, data: storelistData, error: storeListError },
  ] = useLazyQuery(GET_STORES_LIST_ADS, {
    client: dashboardServiceClient,
    fetchPolicy: 'network-only',
  });
    useEffect(() => {
        getStoreList({
            variables: {
                listStoreFilter: {
                    take: 10,
                    skip: 0,
                    search: debouncedSearchTerm,
                    filter: {
                        status: 'active',
                    },
                },
            },
        });
    }, [getStoreList,debouncedSearchTerm])
  

  useEffect(() => {
    if (storelistData && storelistData.stores && storelistData.stores.results) {
      setStoreOptions(storelistData.stores.results);
    }
  }, [storelistData]);

  return (
    <Autocomplete
      options={storeOptions.map((item) => ({
        value: item.id,
        label: item.name,
      }))}
      getOptionLabel={(option) => option.label || ''}
      loading={storeListLoading}
      onChange={(event,newValue)=>{
        setSelectStore(newValue?.value);
      }}
      onInputChange={(event, newValue) => {
        if ((event && event?.type === "reset") || newValue === "") {
          setInputValue(""); 
        } else if (event && event.type === "change") {
          setInputValue(newValue);
        }
      }}
      onBlur={() => {
        setInputValue("");
      }}
          sx={{
              "& .MuiInputBase-root": {
                  height: 40,
                  px: 2,
                  backgroundColor: "white.main",
              },
          }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Select a store"
          variant="outlined"
        />
      )}
    />
  );
}

export default StoreDropdown;
