import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { gql, useLazyQuery } from "@apollo/client";
import axios from "axios";
import { dashboardServiceClient } from "graphql/client";
import { useNotification } from "context";
import { Grid } from "@mui/material";
import MDButton from "components/MDButton";
import { UploadSharp } from "@mui/icons-material";
import videojs from "video.js";

const GET_SIGNED_URL = gql`
  query getSignedUrl($getSignedUrlRequest: GetSignedUrlInput!) {
    getSignedUrl(getSignedUrlRequest: $getSignedUrlRequest) {
      url
      readUrl
    }
  }
`;

export default function VideoUploaderContainer({
  preLoadedFile,
  isMultiple,
  signedUrlCallback,
  fieldName,
  isFieldValid,
  type,
  reset,
}) {
  const [componentKey, setComponentKey] = useState(Date.now());

  const preFileInstances =
    (preLoadedFile &&
      preLoadedFile.map((file, index) => ({ id: index, fileName: file }))) ||
    [];
  const stateValue = isMultiple
    ? [...preFileInstances, { id: Date.now() }]
    : preFileInstances.length
    ? [...preFileInstances]
    : [{ id: Date.now() }];

  const [fileInstances, setFileInstances] = useState(stateValue);

  const addNewInstance = () => {
    isMultiple && setFileInstances([...fileInstances, { id: Date.now() }]);
  };

  const deleteInstance = (idToDelete) => {
    setFileInstances(
      fileInstances.filter((instance) => instance.id !== idToDelete)
    );
  };

  return (
    <div key={componentKey}>
      {fileInstances.map((instance, index) => (
        <Grid container key={instance.id}>
          <Grid item xs={10} sm={12}>
            <FileUploader
              setComponentKey={setComponentKey}
              id={index}
              instance={instance}
              addNewInstance={addNewInstance}
              deleteInstance={deleteInstance}
              signedUrlCallback={signedUrlCallback}
              fieldName={fieldName}
              type={type}
              isMultiple={isMultiple}
              isFieldValid={isFieldValid}
            />
          </Grid>
        </Grid>
      ))}
    </div>
  );
}

function FileUploader({
  setComponentKey,
  addNewInstance,
  deleteInstance,
  id,
  instance,
  signedUrlCallback,
  fieldName,
  isMultiple,
  isFieldValid,
  type,
}) {
  const { setNotification } = useNotification();
  const [fileName, setFileName] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploaded, setIsUploaded] = useState(
    instance.fileName ? true : false
  );
  const [fileData, setFileData] = useState(null);
  const [axiosCallMade, setAxiosCallMade] = useState(false);
  const [readUrl, setReadUrl] = useState(
    instance.fileName ? instance.fileName : null
  );
  const [openPreview, setOpenPreview] = useState(false);
  const [videoMetadata, setVideoMetadata] = useState(null);

  const fileInputId = `${fieldName}-fileInput-${id}`;

  const [getSignedUrl, { data }] = useLazyQuery(GET_SIGNED_URL, {
    client: dashboardServiceClient,
  });

  useEffect(() => {
    if (data?.getSignedUrl?.url && fileName && !axiosCallMade) {
      const uploadFile = async () => {
        await axios.put(data?.getSignedUrl?.url, fileData, {
          headers: { "Content-Type": "application/octet-stream" },
        });
        setUploadProgress(66);
        setAxiosCallMade(true);
        getSignedUrl({
          variables: {
            getSignedUrlRequest: {
              fileName: `dashboard/ads/${'brandId'}/${fileName}`,
              action: "read",
              contentType: "application/octet-stream",
            },
          },
        });
        addNewInstance();
        setIsUploaded(true);
        setUploadProgress(100);
      };
      uploadFile();
    }
  }, [data?.getSignedUrl?.url, fileName]);

  useEffect(() => {
    if (
      isUploaded &&
      data?.getSignedUrl?.readUrl &&
      fileName &&
      videoMetadata
    ) {
      signedUrlCallback(
        `dashboard/ads/${'brandId'}/${fileName}`,
        fileName,
        data?.getSignedUrl?.readUrl,
        videoMetadata
      );
      setComponentKey(Date.now());
    }
  }, [isUploaded, data?.getSignedUrl?.readUrl]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (!file) return;
    if (!file.name.match(/\.(mp4|MP4)$/)) {
      setNotification({
        color: "warning",
        isVisible: true,
        message: "Selected file format is not allowed.",
      });
      return false;
    }

    if (file.size > 100 * 1024 * 1024) {
      setNotification({
        color: "warning",
        isVisible: true,
        message: "File size exceeds the 100MB limit.",
      });
      return false;
    }

    if (file) {
      const url = URL.createObjectURL(file);

      // Setup video.js to extract metadata
      const videoElement = document.createElement("video");
      videoElement.preload = "metadata";

      // Create a URL for the video file
      videoElement.src = url;

      // Event listener for metadata loading
      videoElement.onloadedmetadata = () => {
        try {
          if (videoElement.duration > 60) {
            setNotification({
              color: "warning",
              isVisible: true,
              message: "File duration exceeds the 60sec limit.",
            });
            return false;
          }
          const videoWidth = videoElement.videoWidth;
          const videoHeight = videoElement.videoHeight;
          const gcd = (a, b) => (b === 0 ? a : gcd(b, a % b));
          const divisor = gcd(videoWidth, videoHeight);
          const aspectRatio = `${videoWidth / divisor}:${
            videoHeight / divisor
          }`;
          if (aspectRatio !== "9:16" ) {
            setNotification({
              color: "warning",
              isVisible: true,
              message: "File aspect ratio must be 9:16.",
            });
            return false;
          }

          const metadata = {
            duration: videoElement.duration,
            orientation: videoWidth > videoHeight ? "Landscape" : "Portrait",
            size: `${(file.size / 1024 / 1024).toFixed(2)} MB`,
            aspectRatio: aspectRatio,
          };

          // Clean up
          URL.revokeObjectURL(url);
          videoElement.remove();
          setVideoMetadata(metadata);
        } catch (e) {
          console.error(e);
        }
      };

      // Error handling
      videoElement.onerror = (e) => {
        reject(e);
      };

      setFileName(file.name);
      setFileData(e.target.files[0]);

      setUploadProgress(40);
      getSignedUrl({
        variables: {
          getSignedUrlRequest: {
            fileName: `dashboard/ads/${'brandId'}/${file.name}`,
            action: "write",
            contentType: "application/octet-stream",
          },
        },
      });

      setAxiosCallMade(false);
    }
  };

  const handleDelete = () => {
    deleteInstance(instance.id);
    signedUrlCallback(null, id);
  };

  const handlePreviewOpen = () => {
    setOpenPreview(true);
  };

  const handlePreviewClose = () => {
    setOpenPreview(false);
  };

  return (
    <>
      <Paper
        elevation={0}
        onClick={() =>
          !isUploaded && document.getElementById(fileInputId).click()
        }
        sx={{
          position: "relative",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          // padding: "8px 0px 8px 16px",
          //   backgroundColor: isUploaded ? "#6a994e29" : "#fafafa",
          backgroundColor: "inherit",
          maxHeight: "52px",
          borderRadius: "4px",
          //   border: `1px ${isFieldValid ? "solid" : isUploaded ? "solid" : "dashed"} ${isFieldValid ? "#F44335" : isUploaded ? "#6a994e" : "#efefef"
          //     }`,
        }}
      >
        <input
          type="file"
          id={fileInputId}
          style={{ display: "none" }}
          accept="video/*"
          onChange={handleFileChange}
        />

        {fileName && !isUploaded && (
          <div
            style={{
              width: `${uploadProgress}%`,
              backgroundColor: "red",
              height: "5px",
              position: "absolute",
              bottom: 0,
              left: 0,
            }}
          ></div>
        )}
        <div
          style={{
            display: "inline-flex",
            alignItems: "center",
            gap: "8px",
            // padding: "10px 16px",
          }}
        >
          <MDButton
            type="button"
            variant="contained"
            circular={true}
            color="dark"
            onClick={
              isUploaded
                ? isMultiple
                  ? handleDelete
                  : () => {
                      document.getElementById(fileInputId).click();
                    }
                : null
            }
            sx={{ borderRadius: "100px", mr: 1, width: "max-content" }}
          >
            <UploadSharp /> Upload New Video
          </MDButton>
        </div>
      </Paper>

      <Modal
        open={openPreview}
        onClose={handlePreviewClose}
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <img
          src={readUrl}
          alt="Preview"
          style={{ maxWidth: "80%", maxHeight: "80%" }}
        />
      </Modal>
    </>
  );
}
