// Usage:
import { gql } from "@apollo/client";

export const GET_CATEGORIES = gql`
  query categoryProduct(
    $listCategoryProductFilter: ListCategoryProductFilter!
  ) {
    categoryProduct(listCategoryProductFilter: $listCategoryProductFilter) {
      total
      results {
        id
        title
        metaTitle
        image
        displayOrder
        isDeleted
        isMenuItem
        isNodeItem
        createdAt
        updatedAt
        parent {
          id
          title
          metaTitle
        }
        categoryProductAttribute {
          id
          values
          attributeName
          fieldName
          attributeType
          fieldType
          isMandatory
          displayOrder
          isDeleted
          createdAt
          updatedAt
          fieldType
          masterAttribute {
            id
            name
            fieldType
            description
            isFilter
            values
          }
        }
      }
    }
  }
`;

export const CREATE_CATEGORY_MUTATION = gql`
  mutation createCategoryProduct(
    $createCategoryProductInput: CreateCategoryProductInput!
  ) {
    createCategoryProduct(
      createCategoryProductInput: $createCategoryProductInput
    ) {
      id
      title
      metaTitle
      image
      displayOrder
      isDeleted
      isMenuItem
      createdAt
      updatedAt
      parent {
        title
        metaTitle
      }
    }
  }
`;

export const UPDATE_CATEGORY_MUTATION = gql`
  mutation updateCategoryProduct(
    $updateCategoryProductInput: UpdateCategoryProductInput!
  ) {
    updateCategoryProduct(
      updateCategoryProductInput: $updateCategoryProductInput
    ) {
      id
      title
      metaTitle
      image
      displayOrder
      isDeleted
      isMenuItem
      createdAt
      updatedAt
      parent {
        title
        metaTitle
      }
    }
  }
`;

export const CREATE_OR_UPDATE_CATEGORY_PRODUCT_ATTRIBUTE = gql`
  mutation ($request: CategoryProductAttributeInput!) {
    createOrupdateCategoryProductAttribute(request: $request) {
      id
    }
  }
`;
