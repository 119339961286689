import React, { useState, useEffect, useRef } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Grid, TextField, Button, Autocomplete } from "@mui/material";
import { dashboardServiceClient } from "graphql/client";
import { useMutation } from "@apollo/client";
import { UPDATE_STORE_MUTATION } from "services/store-service";
import { GET_STORES } from "services/store-service";
import { useNotification } from "context";
import Loader from "components/MDLoader";
import MDButton from "components/MDButton";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";

const validationSchema = Yup.object().shape({
  officeAddressLine1: Yup.string().required(
    "Select Address 1 is required"
  ),
  // officeAddressLine2: Yup.string().required(
  //   "Office Address Line 2 is required"
  // ),
  city: Yup.string().required("City is required"),
  state: Yup.string().required("State is required"),
  pincode: Yup.string()
    .required("Pincode is required")
    .max(6, "Maximum 6 characters allowed")
    .matches(/^\d+$/, "Pincode must be a number")
    .matches(/^[1-9]{1}[0-9]{5}$/, "Invalid Pincode"),
  longitude: Yup.string()
    .required("Longitude is required")
    .matches(
      /^-?([1-9]?[1-9]|[1-9]0)\.{1}\d{1,6}/,
      "Longitude must be a valid value"
    ),
  latitude: Yup.string()
    .required("Latitude is required")
    .matches(
      /^-?([1-9]?[1-9]|[1-9]0)\.{1}\d{1,6}/,
      "Latitude must be a valid value"
    ),
});

const AddressAutocomplete = ({ formik }) => {
  const inputRef2 = useRef(null);

  useEffect(() => {
    const loadGoogleMaps = () => {
      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyBbisoFUQBoPH2uM1i_oOv43tC6Bvq9lm8&libraries=places`;
      script.async = true;
      script.onload = () => {
        const autocomplete = new window.google.maps.places.Autocomplete(inputRef2.current);
      
        const observer = new MutationObserver((mutationsList, observer) => {
          const pacContainer = document.querySelector('.pac-container');
          if (pacContainer) {
            // Set the z-index because the options were not visible 
            pacContainer.style.zIndex = '9999';
            observer.disconnect();
          }
        });
        observer.observe(document.body, { childList: true, subtree: true });
        autocomplete.addListener("place_changed", () => {
          const place = autocomplete.getPlace();
          if (formik.values.officeAddressLine1 && inputRef2?.current?.value) {
            inputRef2.current.value = formatAddress1(place);
          }
          if (place) {
            const formattedAddress = formatAddress1(place);
            formik.setFieldValue("officeAddressLine1", place.name + " " + formattedAddress);
            // formik.setFieldValue("officeAddressLine2", place.name);
            formik.setFieldValue(
              "city",
              place?.address_components?.find((comp) =>
                comp.types.includes("locality")
              )?.long_name || ""
            );
            formik.setFieldValue(
              "longitude", place?.geometry?.location?.lng().toString()
            );
            formik.setFieldValue(
              "latitude", place?.geometry?.location?.lat().toString()
            );
            formik.setFieldValue(
              "state",
              place?.address_components?.find((comp) =>
                comp.types.includes("administrative_area_level_1")
              )?.long_name || ""
            );
            formik.setFieldValue(
              "pincode",
              place?.address_components?.find((comp) =>
                comp.types.includes("postal_code")
              )?.long_name || ""
            );
          }
        });
      };
      document.body.appendChild(script);
    };

    loadGoogleMaps();
  }, []); // Only run once on initial mount


  const formatAddress1 = (place) => {
    // Extract address components to filter out city, state, country, and pincode
    const addressParts = place?.address_components?.filter((component) => {
      const types = component?.types;
      return !(
        types.includes("locality") || // City
        types.includes("administrative_area_level_1") || // State
        types.includes("administrative_area_level_2") || // State
        types.includes("administrative_area_level_3") || // State
        types.includes("country") || // Country
        types.includes("postal_code") // Pincode
      );
    });

    // Combine the remaining address parts into a string
    return addressParts?.map((component) => component.long_name).join(", ");
  };

  return (
    <TextField
      label="Select Address 1"
      inputRef={inputRef2}
      value={formik?.values?.officeAddressLine1 || ""}
      error={formik.touched.officeAddressLine1 && Boolean(formik.errors.officeAddressLine1)}
      helperText={formik.touched.officeAddressLine1 && formik.errors.officeAddressLine1}
      style={{ zIndex: 9999 }}
      onChange={(e) => formik?.setFieldValue("officeAddressLine1", e.target.value)}
      fullWidth
      variant="outlined"
    />
  );
};

const AddressFormComponent = ({
  handleClose,
  storeData,
  handleTabChange,
  setStoreData,
}) => {
  const [address, setAddress] = useState([]);
  const [addressLoading, setAddressLoading] = useState(false);
  const [updateStoreMutation, { loading, error }] = useMutation(
    UPDATE_STORE_MUTATION,
    {
      client: dashboardServiceClient,
      refetchQueries: [
        {
          query: GET_STORES,
          variables: { listStoreFilter: { take: 100, skip: 0 } },
        }, // replace "yourSearchTerm" with the actual term you'd use
      ],
    }
  );
  const handleAddressChange = async (e) => {
    setAddressLoading(true)
    try {
      const response = await fetch(`https://nominatim.openstreetmap.org/search?q=${e.target.value}&format=json&polygon_kml=1&addressdetails=1`);
      let data = await response.json();
      data = data.filter(d => d.address.country_code === "in");
      if (data.length > 0) {
        setAddress(data);
      }
    } catch (err) {
      console.error('Error: fetching address data', err);
    }
    setAddressLoading(false)
  };
  const { setNotification } = useNotification();
  if (loading)
    return (
      <>
        <Loader />
      </>
    );
  if (error) return <p>Error: {error?.message}</p>;
  return (
    <Formik
      initialValues={{
        officeAddressLine1: storeData ? storeData.address1 : "",
        officeAddressLine2: storeData ? storeData.address2 : "",
        city: storeData ? storeData.city : "",
        state: storeData ? storeData.state : "",
        pincode: storeData ? storeData.pincode : "",
        longitude: storeData ? storeData.longitude : "",
        latitude: storeData ? storeData.latitude : "",
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        updateStoreMutation({
          variables: {
            updateStoreInput: {
              id: storeData.id,
              address1: values.officeAddressLine1,
              address2: values.officeAddressLine2,
              city: values.city,
              latitude: values.latitude,
              longitude: values.longitude,
              pincode: values.pincode,
              state: values.state,
            },
          },
        })
          .then((res) => {
            // Note: This will be the response of the mutation
            setStoreData(storeData);
            handleTabChange(null, 2);
            setNotification({
              color: "success",
              isVisible: true,
              message:
                storeData && storeData?.id
                  ? "Store Address  updated successfully"
                  : "Store Address  created successfully",
            });
          })
          .catch((error) => {
            console.error("Error:", error?.message);
            setNotification({
              color: "error",
              isVisible: true,
              message: error?.message || "Something went wrong",
            });
          });
      }}
    >
      {(formik) => {
        const { errors, touched, setFieldValue, values } = formik;
        return <Form key={JSON.stringify(storeData)}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              {/* <Field
                name="officeAddressLine1"
                as={TextField}
                label="Address Line 1"
                fullWidth
                error={
                  touched.officeAddressLine1 && !!errors.officeAddressLine1
                }
                helperText={
                  touched.officeAddressLine1 && errors.officeAddressLine1
                }
              /> */}
              <Grid item xs={12}>
                <AddressAutocomplete formik={formik} />
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Field
                name="officeAddressLine2"
                as={TextField}
                label="Address Line 2"
                fullWidth
                error={
                  touched.officeAddressLine2 && !!errors.officeAddressLine2
                }
                helperText={
                  touched.officeAddressLine2 && errors.officeAddressLine2
                }
              />
            </Grid>
            <Grid item xs={4}>
              <Field
                name="city"
                as={TextField}
                label="City"
                fullWidth
                error={touched.city && !!errors.city}
                helperText={touched.city && errors.city}
              />
            </Grid>
            <Grid item xs={4}>
              <Field
                name="state"
                as={TextField}
                label="State"
                fullWidth
                error={touched.state && !!errors.state}
                helperText={touched.state && errors.state}
              />
            </Grid>
            <Grid item xs={4}>
              <Field
                name="pincode"
                as={TextField}
                label="Pincode"
                fullWidth
                error={touched.pincode && !!errors.pincode}
                helperText={touched.pincode && errors.pincode}
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                name="longitude"
                as={TextField}
                label="Longitude"
                fullWidth
                error={touched.longitude && !!errors.longitude}
                helperText={touched.longitude && errors.longitude}
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                name="latitude"
                as={TextField}
                label="Latitude"
                fullWidth
                error={touched.latitude && !!errors.latitude}
                helperText={touched.latitude && errors.latitude}
              />
            </Grid>
            <Grid container xs={12} sx={{ mt: 4, ml: 2, pb: 2 }} flexDirection={"row-reverse"} gap={3}>
                <MDButton
                  onClick={formik.handleSubmit}
                  variant="contained"
                  circular={true}
                  color="dark"
                >
                  Save
                </MDButton>
                <MDButton color="dark" onClick={handleClose} variant="outlined" circular={true}>
                  Cancel
                </MDButton>
              </Grid>
          </Grid>
        </Form>
      }}
    </Formik>
  );
};

export default AddressFormComponent;
