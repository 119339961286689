// Usage:
import { gql } from "@apollo/client";

export const GET_EVENTS = gql `
query events($eventListFilter: EventListFilter!) {
  events (eventListFilter: $eventListFilter) {
    total
    results{
      id
      name
      attendeeType
      eventType
      storeId
      eventLocation
      sponserTier
      startDate
      endDate
      startTime
      endTime
      categoryId
      isSampling
      description
      heroImage
      carouselImages
      termsCondition
      isPublished
      isLive
      createdAt
      product{
        productId
        shortName
        itemName
      }
      sponser{
        id
        brandId
        sponserTier
        isApproved
        isDeleted
        spocContact
        spocName
        operationContact
        operationName
      }
      displayOrder
      ticket{
        id
        name
        ticketStatus
        description
        color
        quantity
        minBuy
        maxBuy
        startDate
        endDate
        startTime
        endTime
        hideOn
        isDeleted
        displayOrder
        duration
        slotCount
        prices {
          id
          mode
          price
          apply
          value
          priceType
        }
        product {
          productId
          shortName
          itemName        }
        }
    }  
  }
}
`;

export const CREATE_EVENT_MUTATION = gql `
  mutation createEvent($createEventInput: CreateEventInput!) {
    createEvent(createEventInput: $createEventInput) {
      id
       name
       attendeeType
       eventType
       storeId
       eventLocation
       sponserTier
       startDate
       endDate
       startTime
       endTime
       categoryId
       isSampling
       description
       heroImage
       carouselImages
       termsCondition
       isPublished
       isLive
       product{
         id
         productId
       }
   }
 
  }
`;

export const UPDATE_EVENT_MUTATION = gql `
  mutation updateEvent($updateEventInput: UpdateEventInput!) {
    updateEvent(updateEventInput: $updateEventInput) {
      id
       name
       attendeeType
       eventType
       storeId
       eventLocation
       sponserTier
       startDate
       endDate
       startTime
       endTime
       categoryId
       isSampling
       description
       heroImage
       carouselImages
       termsCondition
       isPublished
       isLive
       product{
         id
         productId
       }
   }
  }
`;


export const CREATE_SPONSER = gql `
mutation CreateEventSponser ($createEventSponserInput : CreateEventSponserInput!) {
  createEventSponser(createEventSponserInput: $createEventSponserInput) {
    id
    brandId
    sponserTier
    isApproved
    isDeleted
    displayOrder
    createdAt
    updatedAt
    createdBy
    updatedBy
  }
}
`;
export const UPDATE_SPONSER = gql `
mutation UpdateEventSponser ($updateEventSponserInput : UpdateEventSponserInput!) {
  createEventSponser(updateEventSponserInput: $updateEventSponserInput) {
    id
    brandId
    sponserTier
    isApproved
    isDeleted
    displayOrder
    createdAt
    updatedAt
    createdBy
    updatedBy
  }
}
`;

export const CREATE_TICKET = gql `
mutation CreateTicket ($createTicketInput : CreateTicketInput!) {
  createTicket( createTicketInput: $createTicketInput){
    id
    name
  }
}
`;

export const UPDATE_TICKET = gql `
mutation updateTicket ($updateTicketInput : UpdateTicketInput!) {
  updateTicket( updateTicketInput: $updateTicketInput){
    id
    name
    prices {
      id
      mode
      priceType
    }
  }
}
`;

export const CREATE_OR_UPDATE_TICKET = gql `
mutation createOrupdateTicket ($request:TicketsInput!){
  createOrupdateTicket(
    request: $request 
  ) {
    id
  }
}
`
export const PARTICIPANTS_LIST = gql `
query bookedTickets($ticketListFilter: OrderTicketListFilter!) {
  bookedTickets(ticketListFilter: $ticketListFilter) {
      total
      results {
        id
        quantity
        invoiceDate
        totalPrice
        status
        order {
          id
          paymentId
          account {
            id
            name
            mobile
          }
        }
        ticket {
          id
          name
        }
      }
  }
}
`
export const GET_PRODUCT_LIST = gql `
  query getVariants($limit: Float!, $skip: Float!,$search: String,$status: variantStatus,$isSample: Boolean,$agGridFilter:
    AgGridFilter
    ) {
    getVariants(filter: { limit: $limit, skip: $skip ,search: $search, status: $status,isSample: $isSample, agGridFilter: $agGridFilter}) {
      variants {
        id
        isSample
        shortName
        itemName
        status
      }
      total
    }
  }
`;