import { useContext, useState } from "react";

// react-router-dom components
import { Link } from "react-router-dom";
import '../style.css'

// Formik and Yup Validators for React Form
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

// @mui material components
import { IconButton, InputAdornment, TextField, Typography, Card, Switch, Grid } from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayoutLanding from "layouts/authentication/components/BasicLayoutLanding";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";

import AuthService from "services/auth-service";
import { AuthContext } from "context";
import MDAlert from "components/MDAlert";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const validationSchema = Yup.object().shape({
    email: Yup.string()
        .email("Invalid email address")
        .required("Email address is required"),
    password: Yup.string()
        .required("Password is required")
    // .min(8, "Password must be at least 8 characters"),
});

const initialValues = {
    email: "",
    password: "",
};

function Login() {
    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    const authContext = useContext(AuthContext);

    const [rememberMe, setRememberMe] = useState(false);
    const [notification, setNotification] = useState(false);

    const handleSetRememberMe = () => setRememberMe(!rememberMe);

    return (
        <BasicLayoutLanding image={bgImage}>
            <Card sx={{ width: "500px", borderRadius: "40px" }} >
                <MDBox
                    borderRadius="lg"
                    mx={2}
                    p={2}
                    mb={1}
                    textAlign="center"
                    sx={{ fontFamily: "Montserrat" }}
                >
                    <MDBox mt={10} pb={1}>
                        <img src={"/images/broadwayLogo.png"} />
                    </MDBox>
                    <MDTypography variant="h4" fontWeight="medium" color="dark" mt={1} sx={{ fontFamily: "Montserrat" }} >
                        Sign In
                    </MDTypography>
                </MDBox>
                <MDBox pt={4} pb={3} px={3}>
                    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={async (values, { setSubmitting }) => {
                        const newUser = { username: values.email, password: values.password };
                        // Handle form submission
                        try {
                            const response = await AuthService.login(newUser);
                            return authContext.login(response);
                        } catch (res) {
                            if (res.hasOwnProperty("message")) {
                                setNotification(true)
                                setTimeout(() => { setNotification(false) }, 3000)
                            } else {
                                setNotification(true)
                                setTimeout(() => { setNotification(false) }, 3000)
                            }
                        } 
                        setSubmitting(false); // Reset submitting state
                    }}>
                        {({ errors, touched, setFieldValue, isSubmitting }) => (
                            <Form>
                                <Grid container spacing={2}>
                                    <Grid item xs={6} md={10} mx="auto">
                                        <Field
                                            as={TextField}
                                            name="email"
                                            label="Email Address"
                                            error={touched.email && !!errors.email}
                                            helperText={touched.email ? errors.email : ""}
                                            fullWidth
                                            inputProps={{ style: { fontFamily: 'Montserrat' } }}
                                            InputLabelProps={{ style: { fontFamily: 'Montserrat' } }}
                                        />
                                    </Grid>
                                    <Grid item xs={6} md={10} mx="auto">
                                        <Field
                                            id="password"
                                            as={TextField}
                                            type={showPassword ? 'text' : 'password'}
                                            name="password"
                                            fullWidth
                                            // value={values.password}
                                            // onChange={handleChange}
                                            inputProps={{ style: { fontFamily: 'Montserrat' } }}
                                            InputLabelProps={{ style: { fontFamily: 'Montserrat' } }}
                                            error={touched.password && !!errors.password}
                                            helperText={touched.password ? errors.password : ""}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowPassword}
                                                            onMouseDown={handleMouseDownPassword}
                                                            edge="end"
                                                        >
                                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                            label="Password"
                                        />
                                    </Grid>
                                    <Grid item ml={5}>
                                        <MDTypography
                                            variant="button"
                                            fontWeight="regular"
                                            color="dark"
                                            onClick={handleSetRememberMe}
                                            sx={{ cursor: "pointer", userSelect: "none", ml: -1, fontFamily: "Montserrat" }}
                                        >
                                            &nbsp;&nbsp;Remember me
                                        </MDTypography>
                                        <Switch {...{ inputProps: { 'aria-label': 'Color switch demo' } }} checked={rememberMe} onChange={handleSetRememberMe} color="secondary" />
                                    </Grid>
                                    <Grid zIndex={5} item xs={6} md={10} mx="auto">
                                        <MDButton variant="gradient" type="submit" color="dark" fullWidth sx={{ borderRadius: "50px", fontFamily: "Montserrat" }}
                                            className="montserrat" >
                                            Sign In
                                        </MDButton>
                                    </Grid>
                                    <Grid item xs={6} md={10} mx="auto">
                                        <MDTypography variant="button" color="dark" sx={{ fontFamily: "Montserrat" }}>
                                            Forgot your password?{" "}
                                            <Typography
                                                component={Link}
                                                to="/auth/forgot-password"
                                                variant="button"
                                                color="dark"
                                                fontWeight="medium"
                                                sx={{ fontFamily: "Montserrat", textDecoration: "underline" }}
                                            >
                                                <Typography sx={{ textDecoration: 'underline', display: "inline", color: "#000", textTransform: "capitalize", fontFamily: "Montserrat", fontWeight: 600, fontSize: "15px" }}>Reset Here</Typography>
                                            </Typography>
                                        </MDTypography>
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                </MDBox>
            </Card>

            {notification && (
                <MDAlert color="error" mt="20px" dismissible sx={{ position: "absolute", top: "10px", right: "10px" }}>
                    <MDTypography variant="body2" color="white" className="montserrat">
                        {"Please check your email or password is valid."}
                    </MDTypography>
                </MDAlert>
            )}
        </BasicLayoutLanding>
    );
}

export default Login;
